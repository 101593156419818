import React from 'react'
import ConnectButton from '../components/TonConnect'

const Wallet = () => {
  return (
    <div>
      <ConnectButton />
    </div>
  )
}

export default Wallet