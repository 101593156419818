import React, { useCallback, useState, useEffect } from "react";
import { useTonConnectUI } from "@tonconnect/ui-react";
import UserService from '../service/UserService'; // وارد کردن سرویس

const TonConnectButton = ({ onShowSnackbar }) => {
  const [tonConnectUI] = useTonConnectUI();
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // تغییر اندازه صفحه
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // تابع برای دریافت اندازه آیکون براساس عرض صفحه
  const getIconSize = () => {
    if (windowWidth >= 769) {
      return { width: '50px', height: '50px', marginRight: '10px' };
    } else if (windowWidth >= 426) {
      return { width: '45px', height: '45px', marginRight: '8px' };
    } else if (windowWidth >= 376) {
      return { width: '30px', height: '30px', marginRight: '6px' };
    } else {
      return { width: '20px', height: '20px', marginRight: '5px' };
    }
  };

  const handleConnection = useCallback(async () => {
    setIsLoading(true);
    try {
      if (tonConnectUI.connected) {
        await tonConnectUI.disconnect();
      } else {
        await tonConnectUI.connectWallet();
        const walletAddress = tonConnectUI.account?.address;
        if (walletAddress) {
          const updatedData = { wallet: walletAddress };
          try {
            const result = await UserService.updateUserDetails(updatedData);  // فراخوانی متد بروزرسانی
            console.log('Updated user data:', result);  // نمایش داده‌های به‌روزرسانی شده
            if (result) {
              // showToast('Wallet connected and address updated successfully!', 'success');
            } else {
              // showToast('Failed to update wallet address.', 'error');
            }
          } catch (err) {
            // showToast('Error updating user data', 'error');
          }
        }
      }
    } catch (err) {
      console.error("Connection error:", err);
      const errorMessage =
        err.message === "Operation aborted"
          ? "Connection aborted. Please try again."
          : "Connection error. Please try again.";
      onShowSnackbar(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [tonConnectUI, onShowSnackbar]); // افزودن showToast به وابستگی‌ها

  const getWalletName = () => {
    return tonConnectUI.account?.address ?? "Unknown wallet";
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  return (
    <div>
      <button
        onClick={handleConnection}
        className="button"
        disabled={isLoading}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <img
          src="/assets/img/wallets.svg"
          alt="Icon"
          style={getIconSize()}  // استفاده از استایل داینامیک
        />
        <span style={{ width: "auto" }}>
          {isLoading
            ? "Connecting . . ."
            : tonConnectUI.connected
              ? truncateString(getWalletName(), 20)
              : "Connect Wallet"}
        </span>
      </button>
    </div>
  );
};

export default TonConnectButton;
