import React, { useState, useEffect } from 'react'
import BottomSheet from './BottomSheet'
import UserService from '../service/UserService'

const InviteLinkManager = () => {
  const [inviteCode, setInviteLink] = useState('')
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  useEffect(() => {}, [isSheetOpen])

  const generateInviteLink = async () => {
    setIsSheetOpen(true)

    const user = await UserService.getStoredUserData()
    const inviteLink = `https://t.me/OrionCryptoBot/Orion?startapp=${user.inviteid}`
    setInviteLink(inviteLink)
  }

  return (
    <div>
      <button
        onClick={generateInviteLink}
        className="button"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <img src="/assets/img/share.svg" alt="Icon" className="icon" />
        Invite
      </button>

      <BottomSheet isOpen={isSheetOpen} onClose={() => setIsSheetOpen(false)}>
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <h2>Generated Invite Link</h2>
          <p>{inviteCode}</p>
          <button
            onClick={() => {
              navigator.clipboard.writeText(inviteCode)
              alert('Invite link copied to clipboard!')
            }}
            style={{
              padding: '10px 20px',
              backgroundColor: '#2196F3',
              color: 'white',
              borderRadius: '5px',
              marginTop: '10px',
            }}
          >
            Copy Invite Link
          </button>
        </div>
      </BottomSheet>
    </div>
  )
}

export default InviteLinkManager
