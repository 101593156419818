import React, { useEffect, useState } from 'react';

const TypewriterEffect = ({ texts, speed, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [textClearing, setTextClearing] = useState(false);

  useEffect(() => {
    if (textClearing) {
      const timeout = setTimeout(() => {
        setTextClearing(false);
        setDisplayedText('');
      }, 1000);
      return () => clearTimeout(timeout);
    }

    // Still typing current text
    if (index < texts[currentTextIndex].text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + texts[currentTextIndex].text[index]);
        setIndex((prev) => prev + 1);
      }, speed);
      return () => clearTimeout(timeout);
    } 
    
    // Finished typing current text
    if (index === texts[currentTextIndex].text.length) {
      const timeout = setTimeout(() => {
        // If this is the last text
        if (currentTextIndex === texts.length - 1) {
          if (onComplete) {
            onComplete();
          }
        } else {
          // Move to next text
          setTextClearing(true);
          setCurrentTextIndex((prev) => prev + 1);
          setIndex(0);
        }
      }, 2000); // Increased pause time to make last text more visible
      return () => clearTimeout(timeout);
    }
  }, [index, currentTextIndex, textClearing, texts, speed, onComplete]);

  return (
    <div style={{ textAlign: 'center', zIndex: '9999' }}>
      <p style={{ 
        color: texts[currentTextIndex].color, 
        fontSize: '28px', 
        margin: 0 
      }}>
        {displayedText}
      </p>
    </div>
  );
};

export default TypewriterEffect;