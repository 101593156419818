import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import Home from './page/Home'
import Intro from './page/Intro'

import ErrorPage from './page/ErrorPage'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ToastManagerProvider from './hooks/useToast';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastManagerProvider>

        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/home-page" element={<Home />} />
          <Route path="/Intro" element={<Intro />} />
          <Route path="/error-page" element={<ErrorPage />} />
        </Routes>
      </ToastManagerProvider>

    </BrowserRouter>
  </React.StrictMode>
)
