import { supabase } from '../utils/supabaseClient';

class UserService {
  // ورود کاربر
  static async signIn(username, password) {
    try {
      if (!username || !password) {
        throw new Error('Username and password are required.');
      }

      const email = `${username}@OrionCoin.one`;
      console.log('Signing in with:', { email, password });

      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      console.log('Supabase sign in result:', { data, error });

      if (error) {
        console.error('Supabase sign in error:', error);
        throw new Error(error.message);
      }

      return { data };
    } catch (error) {
      console.error('Sign in method error:', error);
      throw new Error(error.message);
    }
  }



  static async signUp(username, password, name, tgusername, tgid, inviteid, wallet, coin, intro) {
    try {
      if (!username) {
        throw new Error('Username is required.');
      }
      const email = `${username}@orioncoin.one`;
      console.log('Signing up with:', { email, username, name, tgusername });

      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });

      console.log('Supabase sign up result:', { data, error });

      if (error) {
        console.error('Supabase sign up error:', error);
        throw new Error(error.message);
      }

      const { error: insertError } = await supabase
        .from('users')
        .insert([
          {
            id: data.user.id,
            email: email,
            username: username,
            name: name,
            tgusername: tgusername,
            tgid: tgid,
            inviteid: inviteid,
            wallet: wallet,
            coin: coin,
            intro: false,
          },
        ]);

      console.log('User insert result:', { insertError });

      if (insertError) {
        console.error('User insert error:', insertError);
        throw new Error(insertError.message);
      }

      return { data };
    } catch (error) {
      console.error('Sign up method error:', error);
      throw new Error(error.message);
    }
  }

  // دریافت اطلاعات کاربر از جدول users
  static async getSession() {
    try {
      const { data, error } = await supabase.auth.getSession();

      if (error) {
        console.error('Error getting session:', error);
        return { data: null, error: error.message };
      }

      console.log('Session data:', data);
      return { data: data.session, error: null };
    } catch (err) {
      console.error('Unexpected error in getSession:', err);
      return { data: null, error: err.message };
    }
  }

  static async getUserDetails() {
    try {
      // Get current session
      const { data: { session } } = await supabase.auth.getSession();

      if (!session) {
        throw new Error('No active session');
      }

      // Log session user details
      console.log('Session User id:', session.user.id);

      // Fetch user details using id instead of email
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', session.user.id)  // تغییر از email به id
        .single();

      if (error) {
        console.error('Supabase query error:', error);
        throw error;
      }

      if (!data) {
        throw new Error('No user data found for this user');
      }

      return data;
    } catch (error) {
      console.error('Complete getUserDetails error:', error);
      throw error;
    }
  }

  static async getUserDetailsByTgid(tgid) {
    try {
      if (!tgid) {
        throw new Error('Telegram ID (tgid) is required.');
      }
      
        console.log('Fetching user details for Telegram ID:', tgid);
  
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('tgid', tgid)  
        .single();
  
      if (error) {
        console.error('Supabase query error:', error);
        throw new Error('Error fetching user details.');
      }
  
      if (!data) {
        throw new Error('No user found for this Telegram ID.');
      }
  
      // Return the user data if found
      return data;
    } catch (error) {
      console.error('Complete getUserDetailsByTgid error:', error);
      throw error;
    }
  }

  // خروج از سیستم
  static async signOut() {
    await supabase.auth.signOut();
    localStorage.removeItem('authToken');
  }

  // رفرش کردن توکن در صورت منقضی شدن
  static async refreshSession() {
    const session = await supabase.auth.getSession();  // تغییر از session() به getSession()
    if (session && session.refresh_token) {
      const { data, error } = await supabase.auth.refreshSession(session.refresh_token);
      if (error) {
        throw new Error('Error refreshing session');
      }
      return data;
    }
    throw new Error('No refresh token available');
  }

  // استفاده از onAuthStateChange برای رصد تغییرات احراز هویت و رفرش کردن توکن
  static initAuthListener() {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
        // کاربر از سیستم خارج شده یا حذف شده است
        localStorage.removeItem('authToken');
      }

      if (session) {
        // ذخیره توکن در localStorage در صورت موفقیت ورود
        localStorage.setItem('authToken', session.access_token);
      } else {
        // توکن منقضی شده است، سعی می‌کنیم آن را رفرش کنیم
        try {
          await UserService.refreshSession();
        } catch (error) {
          console.error('Error refreshing session:', error);
        }
      }
    });
  }

  // دریافت اطلاعات ذخیره‌شده کاربر از localStorage
  static getStoredUserData() {
    const session = supabase.auth.getSession();  // تغییر از session() به getSession()
    if (session && session.user) {
      return session.user;  // اطلاعات کاربر ذخیره‌شده در session
    }
    return null;
  }

  static async updateUserDetails(updatedData) {
    try {
      const { data: { session } } = await supabase.auth.getSession();

      if (!session) {
        throw new Error('No active session');
      }

      const { data, error } = await supabase
        .from('users')
        .update(updatedData)
        .eq('id', session.user.id)  // تغییر از ایمیل به id
        .select();

      if (error) {
        console.error('Update Error:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Complete Error:', error);
      throw error;
    }
  }
}

export default UserService;