import React, { useState, useEffect } from 'react'
import BottomNavigation from '../components/BottomNavigation'
import SlideBottom from '../components/SlideBottom'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import OrionGalacticLogo from '../components/SkyBackground'
import ToastManagerProvider, { useToast } from '../hooks/useToast'

// Import pages
import Dashboard from '../page/Dashboard'
import TopEarn from '../page/TaskPage'
import InviteFriends from '../page/InvitePage'
import Wallet from '../page/WalletPage'

import '../App.css'
import '../css/my.css'


function HomeContent() {
  const [currentPage, setCurrentPage] = useState('page1')
  const [username, setUsername] = useState('')
  const [userId, setUserId] = useState('')

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const user = window.Telegram.WebApp.initDataUnsafe.user

      if (user && user.username && user.id) {
        setUsername(user.username)
        setUserId(user.id)
      } else {
        setUsername('Username not found')
        setUserId('ID not found')
      }
    } else {
      setUsername('Telegram Web App Is Busy')
      setUserId('Telegram Web App Is Busy')
    }
  }, [])

  const renderContent = () => {
    switch (currentPage) {
      case 'page1':
        return <Dashboard key="page1" />
      case 'page2':
        return <TopEarn key="page2" />
      case 'page3':
        return <InviteFriends key="page3" />
      case 'page4':
        return <Wallet key="page4" />
      default:
        return <Dashboard key="page1" />
    }
  }

  return (
    <div className="main-content">
      <OrionGalacticLogo />
      <div className="header" style={{ display: 'none' }}>
        <SlideBottom>
          <div className="containertop">
            <div className="left">
              <img
                src={process.env.PUBLIC_URL + '/assets/img/cup.svg'}
                alt="level"
                className="nav-icon"
              />
              <span id="username" className="username">
                {username}
              </span>
            </div>
            <div className="right" style={{ marginRight: '5px' }}>
              <img
                src={process.env.PUBLIC_URL + '/assets/img/coinearnselected.svg'}
                alt="coin"
                className="nav-icon"
              />
              <span className="coin">11.000.000</span>
            </div>
          </div>
        </SlideBottom>
      </div>

      <SlideBottom key={currentPage}>

        <div className="content">{renderContent()}</div>
      </SlideBottom>

      <div className="footer">
        <BottomNavigation setCurrentPage={setCurrentPage} />
      </div>
    </div>
  )
}

function Home() {
  return (
    <TonConnectUIProvider manifestUrl="https://raw.githubusercontent.com/howardpen9/test-twa-Oct-2023/refs/heads/main/tonconnect-manifest.json">
      <HomeContent />
    </TonConnectUIProvider>
  )
}

export default Home
