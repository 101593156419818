import React, { useState, useRef, useEffect } from "react";
import { Sheet } from "react-modal-sheet";
import { X } from "lucide-react";

const BottomSheet = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);

  const openBottomSheet = () => {
    setIsOpen(true);
  };

  const closeBottomSheet = () => {
    setIsOpen(false);
  };

  const sheetContainerStyle = {
    background: "rgb(17 18 18)",
    color: "white",
    borderRadius: "20px 20px 0 0",
    position: "fixed",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "MyNormalFont",
  };

  const closeIconStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    background: "none",
    border: "none",
    width: "auto",
    zIndex: 10,
  };

  return (
    <>
      <style>
        {`
          @keyframes glowing {
            0% {
              box-shadow: 0 0 5px rgba(255, 255, 255, 0.5),
                          0 0 10px rgba(255, 255, 255, 0.3),
                          0 0 15px rgba(255, 255, 255, 0.1);
            }
            50% {
              box-shadow: 0 0 10px rgba(255, 255, 255, 0.5),
                          0 0 20px rgba(255, 255, 255, 0.3),
                          0 0 30px rgba(255, 255, 255, 0.1);
            }
            100% {
              box-shadow: 0 0 5px rgba(255, 255, 255, 0.5),
                          0 0 10px rgba(255, 255, 255, 0.3),
                          0 0 15px rgba(255, 255, 255, 0.1);
            }
          }
        `}
      </style>
      <div>
        <Sheet
          disableDrag={true}
          isOpen={isOpen}
          onClose={closeBottomSheet}
          snapPoints={[contentHeight, 0]}
          initialSnap={0}
        >
          <Sheet.Container style={sheetContainerStyle}>
            <button onClick={closeBottomSheet} style={closeIconStyle}>
              <X size={24} color="white" />
            </button>
            <Sheet.Content style={{ flex: 1, overflowY: "auto" }}>
              <div
                ref={contentRef}
                style={{
                  padding: "20px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* محتوا به صورت داینامیک از props.children دریافت می‌شود */}
                {children}
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            style={{ background: "rgba(0, 0, 0, 0.7)" }}
          />
        </Sheet>
      </div>
    </>
  );
};

export default BottomSheet;
