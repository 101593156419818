// src/components/ShimmerLoading.js
import React from 'react';
import { Image, Shimmer } from 'react-shimmer'

const ShimmerLoading = () => {
    return (
        <div className="dashboard-container">

        </div>
    );
};

export default ShimmerLoading;
