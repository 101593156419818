import React, { useState, useEffect } from 'react';
import '../css/dashboard.css';
import TipsDisplay from '../components/TipsDisplay';
import UserService from '../service/UserService';
import ShimmerLoading from '../components/DashboardLoader'; // Import the shimmer loading component

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const { data: session, error: sessionError } = await UserService.getSession();

        console.log('Session data:', session);
        console.log('Session error:', sessionError);

        if (sessionError || !session) {
          throw new Error(sessionError || 'Session not found');
        }

        // Add more detailed logging here
        const userData = await UserService.getUserDetails();
        console.log('Raw userData:', userData);

        if (!userData) {
          console.error('No user data retrieved');
          setError('Failed to retrieve user details');
        } else {
          setUser(userData);
        }
      } catch (err) {
        console.error('Complete error details:', err);
        setError(err.message || 'Failed to fetch user data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const NumberWithCommas = ({ number }) => {
    const formattedNumber = new Intl.NumberFormat().format(number);
    return <span className="token-text">{formattedNumber}</span>;
  };

  if (isLoading) {
    return (
      <div className="dashboard-container">

      </div>
  );  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="no-user-container">
        <p>No user data available. Please log in.</p>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="orion-logo-wrapper">
          <div className="orion-logo"></div>

          <div className="rank-text-wrapper">
            <img
              src="/assets/img/star.svg"
              alt="Rank Icon"
              className="rank-icon"
              style={{ marginRight: '5px' }}
            />
            <span className="rank-text">
              {user.tgusername || 'Username Not Available'}
            </span>
          </div>

          <div className="token-text-wrapper">
            <span className="token-text">
              <NumberWithCommas number={user.coin || 0} />
            </span>
          </div>

          <div className="rank-text-wrapper">
            <img
              src="/assets/img/rank.svg"
              alt="Rank Icon"
              className="rank-icon"
            />
            <span className="rank-text">Rank {'>'}</span>
          </div>
        </div>

        <div className="stats-container">
          <div className="stat-box">
            <button
              className="button"
              onClick={() => window.open("https://t.me/orioncryptoai", "_blank")}
            >
              <img
                src="/assets/img/group.svg"
                alt="Community Icon"
                className="button-icon"
              />
              Join Community
            </button>

            <button
              className="button"
              onClick={() => window.open("https://www.youtube.com/@OrionCryptoAi", "_blank")}
            >
              <img
                src="/assets/img/youtube.svg"
                alt="Community Icon"
                className="button-icon"
              />
              Join Youtube
            </button>

            <button
              className="button"
              onClick={() => window.open("https://x.com/OrionCryptAi", "_blank")}
            >
              <img
                src="/assets/img/twiter.svg"
                alt="Community Icon"
                className="button-icon"
              />
              Join X
            </button>

            <div className="tips">
              <TipsDisplay />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
