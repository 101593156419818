import '../src/css/splash.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OrionGalacticLogo from './components/SkyBackground';
import useTelegramUser from './hooks/useTelegramUser';
import { useToast } from './hooks/useToast';
import UserService from './service/UserService';
import { useInviteCode } from './hooks/useInviteCode';

function AppContent() {
  const { showToast } = useToast();
  const { error: userError, user: telegramUser } = useTelegramUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { generateInviteCode } = useInviteCode();
  const [startParam, setStartParam] = useState(null);

  useEffect(() => {
    const initTelegramData = () => {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe) {
          const startParamFromTelegram = window.Telegram.WebApp.initDataUnsafe.start_param;
          console.log('Start Param from Telegram:', startParamFromTelegram);
          setStartParam(startParamFromTelegram || null);
        } else {
          console.log('Telegram WebApp not fully initialized');
        }
      } catch (error) {
        console.error('Error initializing Telegram data:', error);
      }
    };

    initTelegramData();
  }, [telegramUser]);

  const navigateToIntroAfterDelay = (param = null) => {
    console.log('Navigating to Intro with param:', param);
    setTimeout(() => {
      if (param) {
        navigate(`/Intro?start_param=${param}`);
      } else {
        navigate('/Intro');
      }
    }, 2000);
  };

  useEffect(() => {
    const checkAndCreateUser = async () => {
      try {
        console.log('Checking user - Telegram User:', telegramUser);
        console.log('User Error:', userError);
        console.log('Start Param:', startParam);

        const storedUser = UserService.getStoredUserData();
        console.log('Stored User:', storedUser);

        if (storedUser) {
          navigateToIntroAfterDelay(startParam);
          return;
        }

        if (telegramUser && !userError) {
          const username = telegramUser.id.toString();
          const password = username;
          const inviteCodes = generateInviteCode(telegramUser.id);

          try {
            await UserService.signUp(
              username,
              password,
              telegramUser.first_name,
              telegramUser.username,
              telegramUser.id,
              inviteCodes,
              '',
              '0',
              false
            );

            const signInResult = await UserService.signIn(username, password);
            if (signInResult.data) {
              localStorage.setItem('authToken', signInResult.data.access_token);
              navigateToIntroAfterDelay(startParam);
            }
          } catch (authError) {
            if (authError.message.includes('User already registered')) {
              try {
                const signInResult = await UserService.signIn(username, password);
                if (signInResult.data) {
                  localStorage.setItem('authToken', signInResult.data.access_token);
                  navigateToIntroAfterDelay(startParam);
                }
              } catch (signInError) {
                setError(signInError.message || 'Login failed');
                console.error('Sign in error:', signInError);
              }
            } else {
              setError(authError.message || 'Registration failed');
              console.error('Auth error:', authError);
            }
          }
        }
      } catch (err) {
        setError(err.message || 'Authentication failed');
        console.error('Unexpected error:', err);
      } finally {
        setIsLoading(false);
      }
    };

    checkAndCreateUser();
  }, [telegramUser, userError, startParam, navigate]);

  return (
    <div className="app-container">
      <OrionGalacticLogo />
      <div className="logo-container">
        <img
          src="/assets/img/orion.svg"
          alt="Orion Galactic Logo"
          className="logo-image"
        />
      </div>

      {userError ? (
        <div
          className="error-container"
          style={{ textAlign: 'center', zIndex: '9999' }}
        >
          <p style={{ color: 'white', fontSize: '18px', margin: 0 }}>
            Oops! Something went wrong!
          </p>
          <p style={{ color: 'white', fontSize: '23px', margin: 0 }}>
            {userError}
          </p>
        </div>
      ) : (
        <div
          className="error-container"
          style={{ textAlign: 'center', zIndex: '9999' }}
        >
          <p style={{ color: 'white', fontSize: '18px', margin: 0 }}>
            Welcome To Orion!
          </p>
          <p style={{ color: 'white', fontSize: '23px', margin: 0 }}>
            "Dream Big, Achieve Bigger with Orion Airdrop!"
          </p>
        </div>
      )}
    </div>
  );
}

export default AppContent;