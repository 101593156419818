import React from 'react'
import InviteLinkManager from '../components/InviteManager'
import '../css/invite.css'

const InviteFriends = () => {
  return (
    <div>
      <p class="invite-friends">Invite Friends</p>
      <p class="invite-friends-tips">
        SHARE YOUR INVITATION LINK & RECEIVE 250 TOKENS
      </p>
      <InviteLinkManager />
    </div>
  )
}

export default InviteFriends
