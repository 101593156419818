import React from 'react'
import CardList from '../components/TopErn'
import WeeklyList from '../components/Weekly'
import Tab from '../components/Tab'
import '../css/Task.css';

const Task = () => {
  return (
    <div className="task-container">
      <p className="card-title top-earn-title">Top Earn</p>
      {/* <CardList /> */}

      <p className="card-title weekly-title">Weekly</p>
      {/* <WeeklyList /> */}
      <Tab />
    </div>
  )
}

export default Task
