import React from 'react';
import { useLocation } from 'react-router-dom';  // اضافه کردن ایمپورت useLocation
import OrionGalacticLogo from '../components/SkyBackground'
import '../css/error.css'

function ErrorPage() {
    const location = useLocation();
    const errorMessage = location.state?.error || 'خطای نامشخص';

    return (
        <div className="app-container">
            <OrionGalacticLogo />

            <div className="logo-container">
                <img
                    src="/assets/img/orion.svg"
                    alt="Orion Galactic Logo"
                    className="logo-image"
                />
            </div>

            <div className="shining-text-container">
                <div className="text-wrappers" style={{ fontSize: '50px' }}>
                    <p>{errorMessage}</p>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;