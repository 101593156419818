// TipsDisplay.js
import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { useTips } from '../hooks/useTips';

function TipsDisplay() {
    const { tips, loading, error } = useTips();

    if (loading) return <div>Preparing your planet...</div>;
    if (error) return <div>Error: {error}</div>;

    const sequence = tips.flatMap(tip => [tip.text, 2000]);

    return (
        <div>
            <TypeAnimation
                sequence={sequence}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
                style={{ whiteSpace: "pre-wrap" }}
            />
        </div>
    );
}

export default TipsDisplay;
