import React, { useState } from 'react'
import '../css/tab.css'

const TabBar = () => {
  const [activeTab, setActiveTab] = useState('New')

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const tabs = ['New', 'OnChain', 'Sochial', 'Academy', 'Farming', 'Freinds']

  const tabContent = {
    New: 'This is the content for Daily tab.',
    OnChain: 'This is the content for Tommy tab.',
    Sochial: 'This is the content for tab3.',
    Academy: 'This is the content for tab4.',
    Farming: 'This is the content for tab5.',
    Freinds: 'This is the content for tab6.',
  }

  return (
    <div>
      <div className="tabbar-container">
        <div className="tabbar">
          {tabs.map((tab) => (
            <div
              key={tab}
              className={`tabbar-item ${activeTab === tab ? 'active' : ''}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab.toUpperCase()}
            </div>
          ))}
        </div>
      </div>

      <div className="tab-content">
        <p>{tabContent[activeTab]}</p>
      </div>
    </div>
  )
}

export default TabBar
