import { useState, useEffect } from 'react';

const useTelegramUser = () => {
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [isUserAvailable, setIsUserAvailable] = useState(true); // همیشه true برای تست

    useEffect(() => {
        const checkTelegramUser = () => {

            if (!window.Telegram || !window.Telegram.WebApp) {
                setError('Please run this app through Telegram');
                return false;
            }

            try {
                const webApp = window.Telegram.WebApp;
                if (!webApp.initDataUnsafe || !webApp.initDataUnsafe.user) {
                    setError('Unable to access user information');
                    return false;
                }

                const userInfo = webApp.initDataUnsafe.user;
                if (!userInfo.id) {
                    setError('User ID not found');
                    return false;
                }
                setUser(userInfo);
                setError(null);
                return true;
            } catch (error) {
                setError('Error accessing user information');
                return false;
            }

        };

        const isValidUser = checkTelegramUser();
        setIsUserAvailable(isValidUser);
        if (!isValidUser) {
            setUser(null);
        }
    }, []);

    return { error, user, isUserAvailable };
};

export default useTelegramUser;
