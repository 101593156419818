import React from "react";
import { motion } from "framer-motion";

const SlideBottom = ({ children }) => {
  const variants = {
    hidden: { y: "100%", opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <motion.div initial="hidden" animate="visible" variants={variants}>
      {children}
    </motion.div>
  );
};

export default SlideBottom;
