import { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';

export function useTips() {
    const [tips, setTips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchTips();
    }, []);  // این useEffect فقط زمانی که کامپوننت بارگذاری می‌شود اجرا می‌شود

    const fetchTips = async () => {
        try {
            setLoading(true);  // Set loading to true before making the request
            const { data, error } = await supabase
                .from("tips")
                .select("*")  // اینجا همه فیلدها را انتخاب کرده‌ایم تا بررسی کنیم که فیلدها به درستی ارسال می‌شوند
                .order('created_at', { ascending: false })
                .limit(10);  // محدود کردن به 10 رکورد

            if (error) {
                throw new Error(error.message); // Improved error handling
            }

            // چاپ داده‌های برگشتی
            console.log("Fetched tips data:", data);

            // بررسی داده‌ها
            if (data && data.length > 0) {
                setTips(data);  // اگر داده‌ها موجود باشند، آنها را ذخیره می‌کنیم
            } else {
                console.log("No tips found.");
            }
        } catch (err) {
            console.error("Error fetching tips:", err);
            setError(err.message || 'Failed to fetch tips.');
        } finally {
            setLoading(false);  // Ensure loading is set to false after the request is completed
        }
    };

    return { tips, loading, error };
}
