import { useState, useCallback } from 'react';

export const useInviteCode = () => {
    const [inviteCode, setInviteCode] = useState('');

    const generateInviteCode = useCallback((userId) => {
        const userIdStr = userId.toString();
        const timestamp = Date.now().toString(36);
        const combinedStr = userIdStr + timestamp;
        const code = parseInt(combinedStr).toString(36);
        const newCode = code.slice(0, 8).toUpperCase();
        setInviteCode(newCode);
        return newCode;
    }, []);

    return { inviteCode, generateInviteCode };
};
