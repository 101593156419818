import React, { createContext, useState, useContext, useEffect } from 'react'
import '../css/toast.css'

// کامپوننت Toast
const Toast = ({ text, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose()
    }, 4000)
    return () => clearTimeout(timer)
  }, [onClose])

  return <div className={`toast ${type}`}>{text}</div>
}

const ToastManagerContext = createContext()

const ToastManagerProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  const addToast = (text, type) => {
    const id = Date.now()
    setToasts((prevToasts) => [...prevToasts, { id, text, type }])
  }

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id))
  }

  const showToast = (text, type = 'success') => {
    addToast(text, type)
  }

  return (
    <ToastManagerContext.Provider value={{ showToast }}>
      {children}
      <div className="toast-container">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            text={toast.text}
            type={toast.type}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </div>
    </ToastManagerContext.Provider>
  )
}

export const useToast = () => {
  return useContext(ToastManagerContext)
}

export default ToastManagerProvider
